<template>
    <FormRoot>
        <!-- Dropzone -->
        <Dropzone :value="files" accept="application/pdf" @input="setFiles" />
        <InputHint class="mb-4">
            {{ `Click to select files or drag-and-drop from your computed (PDF max. ${maxSize} per file)` }}
        </InputHint>
        <!-- Form -->
        <Field
            :form.sync="form"
            type="text"
            name="value"
            placeholder="Value in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field
            :form.sync="form"
            type="datetime"
            name="expiration_date"
            placeholder="Expiration date"
            hint="End of validity (format 2021-01-01 23:10:00)"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="formDisabled" :loading="loading" @click="uploadVouchers">
                {{ submitLabel }}
            </Submit>
        </FormActions>
        <progress :max="queueCount" :value="progress" v-if="loading">{{ progress }}%</progress>
    </FormRoot>
</template>

<script>
    import { Alerts, FormRoot, Dropzone, Field, FormActions, InputHint, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    let _ = require('lodash')
    const MAX_SIZE = 4e6 // Laravel max upload size is "approx. 4.5MB" so we limit to 4MB to be sure. Ref: https://docs.vapor.build/resources/storage#file-uploads

    export default {
        props: {
            list: Number,
        },
        data() {
            return {
                files: [],
                startUpload: false,
                fields: {
                    value: null,
                    expiration_date: null,
                },
                queueCount: 0,
                progress: 0,
                loading: false,
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
            Dropzone,
            InputHint,
        },
        methods: {
            async uploadVouchers() {
                if (this.someFilesTooLarge) {
                    Alerts.notificationError(`One or more files are larger than ${this.maxSize}`)
                    return
                }
                // Start progress
                this.loading = true
                this.progress = 0
                this.queueCount = this.filesToUpload.length
                const delay = () => new Promise((resolve) => setTimeout(resolve, 200))
                let successfulUploads = 0

                const chunks = _.chunk(this.filesToUpload, 10)
                try {
                    // Create requests
                    for (const chunk of chunks) {
                        const requests = chunk.map(async (file) => this.createVoucher(file))
                        // Start requests
                        const responses = await Promise.allSettled(requests)
                        const filesUploaded = responses.filter((response) => {
                            return response.status === 'fulfilled'
                        })
                        successfulUploads += filesUploaded.length
                        if (successfulUploads === this.queueCount) {
                            this.$emit('finished')
                        }
                        await delay()
                    }
                } catch (error) {
                    console.error({ error })
                } finally {
                    this.loading = false
                }
            },
            formatToMb(fileSize) {
                const size = isNaN(fileSize) ? 0 : fileSize
                return `${size / 1e6}MB`
            },
            async createVoucher(file) {
                // Reset result
                file.error = false

                // Create form data
                const formData = new FormData()
                formData.append('attach_file', file.file)
                formData.append('value', this.form.value)
                formData.append('expiration_date', this.form.expiration_date)

                // Set config
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }

                // Create voucher
                try {
                    await this.$api.voucherLists.createVoucher(this.list, formData, config)
                    file.success = true
                    this.progress++
                    return file
                } catch (error) {
                    const errors = error.response?.data?.errors
                    file.error = _(errors).values().flatten().value()
                    throw error
                }
            },
            validateFileSize(file) {
                const errors = file.errors || []
                const errorFileTooLarge = this.errorFileTooLarge
                if (file.size > MAX_SIZE && !errors.includes(errorFileTooLarge)) {
                    return [...errors, errorFileTooLarge]
                }
                if (file.size <= MAX_SIZE && errors.includes(errorFileTooLarge)) {
                    return errors.filter((error) => error === errorFileTooLarge)
                }
                return errors
            },
            setFiles(files) {
                // mutating existing array instead of creating a new one to avoid infinite recursion with Dropzone
                files.forEach((file) => {
                    const errors = this.validateFileSize(file)
                    file.error = errors.length > 0 ? errors : null
                })
                this.files = files
            },
        },
        computed: {
            formDisabled: function () {
                return !this.form.submittable || this.loading || this.files.length === 0
            },
            filesToUpload: function () {
                return _.filter(this.files, function (file) {
                    return !file.success
                })
            },
            submitLabel() {
                const filesToUploadLength = this.filesToUpload.length
                if (this.loading && filesToUploadLength > 0) {
                    return `Uploading ${filesToUploadLength} voucher(s)`
                }
                if (filesToUploadLength > 0 && this.queueCount && this.queueCount > this.progress) {
                    return `Retry ${filesToUploadLength} voucher(s)`
                }
                if (filesToUploadLength > 0) {
                    return `Upload ${filesToUploadLength} voucher(s)`
                }
                return 'Upload voucher(s)'
            },
            maxSize() {
                return this.formatToMb(MAX_SIZE)
            },
            errorFileTooLarge() {
                return `The file is larger than ${this.maxSize}`
            },
            someFilesTooLarge() {
                return this.files.some((file) => file.error?.includes(this.errorFileTooLarge))
            },
        },
    }
</script>
