<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="component"
            component="Wallets"
            name="wallet_id"
            hint="Select a destination wallet to transfer the amount"
        ></Field>
        <Field
            :form.sync="form"
            type="number"
            min="0"
            name="amount"
            placeholder="Amount in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field :form.sync="form" type="checkbox" name="forced" placeholder="Do you want to force the transfer?"></Field>
        <template v-if="form.forced">
            <Field :form.sync="form" type="date" name="start_date" hint="Start date" class="mt-3"></Field>
            <Field :form.sync="form" type="date" name="expiration_date" hint="Expiration date" class="mt-3"></Field>
        </template>
        <FormActions>
            <Submit class="float-right" :disabled="disableTransfer" @click="onSubmit">Transfer</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        mixins: [Forms],
        components: {
            Field,
            FormRoot,
            FormActions,
            Submit,
        },
        props: {
            wallet: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    amount: 0,
                    wallet_id: null,
                    forced: false,
                    expiration_date: null,
                    start_date: null,
                },
                messageSuccess: 'The amount has been transferred to the destination wallet',
            }
        },
        computed: {
            disableTransfer() {
                let data = this.form.data()
                return !this.form.submittable || !data.wallet_id || !data.amount || data.amount <= 0.01
            },
        },
        methods: {
            submit() {
                const { wallet_id, amount, forced, start_date, expiration_date } = this.form.data()
                const payload = {
                    wallet_id,
                    amount,
                    forced,
                    ...(start_date && { start_date: `${start_date} 00:00:00` }),
                    ...(expiration_date && { expiration_date: `${expiration_date} 23:59:59` }),
                }
                return this.$api.wallets.transfer(this.wallet, payload)
            },
        },
    }
</script>
