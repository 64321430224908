<template>
    <Badge :color="badgeColor" :large="large" :icon="badgeIcon">
        {{ badgeText }}
    </Badge>
</template>

<script>
    import { Badge } from '@tech_hexeko/design-system'

    export default {
        name: 'AgentStatus',
        components: { Badge },
        props: {
            large: {
                type: Boolean,
                default: false,
            },
            agent: {
                type: Object,
                default: null,
            },
            color: {
                type: String,
                default: 'primary',
            },
            minimal: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            badgeColor() {
                return this.agent ? this.color : 'secondary'
            },
            badgeText() {
                switch (this.minimal) {
                    case true:
                        return this.agent ? this.agent.name : 'Unassigned'
                    default:
                        return this.agent ? `Assigned to ${this.agent.name}` : 'No agent assigned'
                }
            },
            badgeIcon() {
                return 'user'
            },
        },
    }
</script>
