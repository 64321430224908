<template>
    <div v-if="allowance">
        <!-- Status -->
        <template v-if="allowance.status">
            <AllowanceStatus :status="allowance.status" class="mb-1" />
            <AgentStatus :agent="allowance.agent" class="ms-1 mb-1" />
            <div v-if="allowance.status.reason" class="text-muted mb-1 text-xxs">
                <Reason :slug="allowance.status.reason" />
            </div>
        </template>

        <!-- Medias -->
        <div v-if="allowance.medias && allowance.medias.length > 0" class="list-group mb-3">
            <a
                v-for="media in allowance.medias"
                :key="`media-${media.id}`"
                :href="media.path"
                class="list-group-item list-group-item-light list-group-item-action"
                target="_blank"
                ><Icon icon="file-alt" class="me-2" />{{ `${media.name} (${media.file_name})` }}</a
            >
        </div>

        <!-- Allowance -->
        <Sheet class="mt-1" name="Activity">
            <Row name="User" v-if="allowance.user">
                <a href="#" @click.prevent="openUser(allowance.user)">{{ allowance.user.name }}</a>
            </Row>
            <Row name="User created on" v-if="allowance.user">
                {{ allowance.user.created_at | formatDate('DD/MM/YYYY') }}
            </Row>
            <Row name="Group" v-if="allowance.group">
                <a href="#" @click.prevent="openGroup(allowance.group)">{{ allowance.group.name }}</a>
            </Row>
            <Row name="Type" v-if="allowance.metadata && allowance.metadata.type">
                <IconWallet
                    :category="getIconTypeOfAllowance(allowance.metadata.type)"
                    v-tooltip="allowance.metadata.type"
                    small
                />
            </Row>
        </Sheet>

        <!-- Data -->
        <Sheet class="mt-1" name="Amount and dates">
            <Row v-if="allowance.iban_last_four" name="IBAN">••••&nbsp;{{ allowance.iban_last_four }}</Row>
            <Row name="Amount">{{ allowance.amount | currency }}</Row>
            <Row name="Remaining amount">{{ allowance.remaining | currency }}</Row>
            <Row name="Amount per month">{{ allowance.amount_per_month | currency }}</Row>
            <Row name="Total months">{{ allowance.total_months }} month(s)</Row>
            <Row name="Period">{{ allowance.period }}</Row>
            <Row name="Eligible range date"
                >{{ allowance.eligible_range_start | formatDate('DD/MM/YYYY') }}
                <span v-if="allowance.eligible_range_end"
                    >to {{ allowance.eligible_range_end | formatDate('DD/MM/YYYY') }}</span
                ></Row
            >
            <Row name="Payment methods">
                <SelectedPaymentMethod
                    v-for="method in allowance.selected_payment_methods.payment_methods"
                    :paymentMethod="method"
                    :key="`pm-${method.id}`"
                />
            </Row>
        </Sheet>

        <!-- Request -->
        <Sheet class="mt-1" name="Request">
            <Row v-if="metadata" name="Metadata">
                <RawOutput :item="metadata" />
            </Row>
            <Row name="Created at">{{ allowance.created_at | formatDate }}</Row>
            <Row name="Last update">{{ allowance.updated_at | formatDate }}</Row>
        </Sheet>
    </div>
</template>

<script>
    import { Row, Sheet } from '@tech_hexeko/design-system'
    import AllowanceStatus from '@/components/Allowances/status'
    import AgentStatus from '@/components/Agents/Status'
    import IconWallet from '@/components/IconWallet'
    import RawOutput from '@/components/RawOutput'
    import Reason from '@/components/Reason'
    import SelectedPaymentMethod from '@/components/layout/SelectedPaymentMethod'

    export default {
        name: 'AllowanceDetails',
        components: {
            AgentStatus,
            AllowanceStatus,
            IconWallet,
            RawOutput,
            Reason,
            Row,
            SelectedPaymentMethod,
            Sheet,
        },
        props: {
            allowance: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        computed: {
            metadata() {
                return {
                    ...{ exit_date: this.userInGroupExitDate ?? 'none' },
                    ...(this.allowance && this.allowance.metadata),
                }
            },
            userInGroupExitDate() {
                if (
                    this.allowance &&
                    this.allowance.group &&
                    this.allowance.group.meta &&
                    this.allowance.group.meta.exit_date
                ) {
                    return this.allowance.group.meta.exit_date
                }
                return null
            },
        },
        methods: {
            getIconTypeOfAllowance(type) {
                switch (type) {
                    case 'bicycle':
                        return 'sustainable_mobility_package'
                    case 'carpooling':
                        return 'carpooling'
                    case 'subscription':
                        return 'public_transport_passes'
                    default:
                        return 'mobility'
                }
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openGroup(group) {
                this.openPanel('groupPanel', group.id)
            },
        },
    }
</script>
