<template>
    <div>
        <Datatable
            name="Wallet transactions"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:thead>
                <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Label</th>
                    <th>Comment</th>
                    <th>Transaction at</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </template>
            <tr
                v-for="(transaction, index) in transactions"
                :key="index"
                class="cursor-pointer"
                @click="openTransactionModal(transaction)"
                valign="middle"
            >
                <td>
                    <code>{{ transaction.id }}</code>
                </td>
                <td>
                    <Badge :color="transaction.amount > 0 ? 'success' : 'danger'">{{
                        transaction.amount | currency
                    }}</Badge>
                </td>
                <td>{{ transaction.label }}</td>
                <td>
                    {{ transaction.main_comment }}
                    <code v-if="Object.keys(transaction.comments).length > 0" class="d-block text-muted">
                        <span v-for="(comment, key) in transaction.comments" :key="`comment-${key}`">
                            <template v-if="key === 'quote_line_id'">
                                {{ key }}:
                                <a
                                    href="#"
                                    @click.stop="openQuoteModal(comment)"
                                    class="custom-link"
                                    :class="{ 'pe-none opacity-50': loadingLink }"
                                    >{{ comment }}</a
                                ><br />
                            </template>
                            <template v-else-if="key === 'wallet_id'">
                                {{ key }}:
                                <a href="#" @click.stop="openWallet(comment)" class="custom-link">{{ comment }}</a
                                ><br />
                            </template>
                            <template v-else>{{ key }}: {{ comment }}<br /></template>
                        </span>
                    </code>
                    <code v-if="transaction.start_date || transaction.expiration_date" class="d-block text-muted">
                        <template v-if="transaction.start_date">
                            start_date: {{ transaction.start_date | formatDate('DD/MM/YYYY') }} <br />
                        </template>
                        <template v-if="transaction.expiration_date">
                            expiration_date: {{ transaction.expiration_date | formatDate('DD/MM/YYYY') }}
                        </template>
                    </code>
                </td>
                <td>{{ transaction.transaction_at | formatDate }}</td>
                <td>{{ transaction.created_at | formatDate }}</td>
                <td class="text-end"><Icon icon="angle-right" /></td>
            </tr>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Badge, Alerts } from '@tech_hexeko/design-system'

    export default {
        name: 'WalletTransactions',
        props: {
            id: Number,
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                search: null,
                loadingLink: false,
                pagination: {
                    current_page: 1,
                },
                transactions: [],
            }
        },
        components: {
            Datatable,
            Badge,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.wallets
                    .getTransactions(this.id, {
                        search: this.search ? this.search : undefined,
                        page: this.pagination.current_page,
                        include: ['payment_transaction', 'label'],
                    })
                    .then((response) => {
                        this.transactions = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openQuoteModal(quoteLineId) {
                let quoteId = null
                this.loadingLink = true
                this.$api.billing.quotes
                    .showLine(quoteLineId)
                    .then((response) => {
                        quoteId = response.quote_id ? response.quote_id : null
                        this.openPanel('QuotePanel', quoteId)
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loadingLink = false
                    })
            },
            openWallet(walletId) {
                this.openPanel('WalletPanel', walletId)
            },
            openTransactionModal(transaction) {
                let panel = null
                if (transaction && transaction.payment_transaction) {
                    panel = this.openPanel('PaymentTransactionPanel', transaction.payment_transaction.id)
                } else {
                    panel = this.openPanel('WalletTransactionPanel', transaction.id)
                }
                panel.promise.then((result) => {
                    if (result && result.refresh) this.fetchData(this.search)
                })
            },
        },
    }
</script>
<style lang="css" scoped>
    .table-hover tr:hover td code .custom-link {
        color: #ffffff !important;
        text-decoration: underline;
    }
</style>
