<template>
    <Datatable
        name="Expense Requests"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        scroll-x
    >
        <template v-slot:filters>
            <!-- Filter by status -->
            <Dropdown
                v-if="statuses"
                class="me-1 mb-1"
                :color="status ? 'success' : 'secondary'"
                title="Filter by status"
                small
            >
                <li v-for="(avStatus, slug) in statuses" :key="slug">
                    <button
                        @click.prevent="filterByStatus(slug)"
                        class="dropdown-item text-xs"
                        :class="status === slug ? 'bg-primary text-white' : 'bg-white'"
                    >
                        {{ avStatus.name }}
                    </button>
                </li>
            </Dropdown>
            <!-- Filter by type(s) -->
            <Dropdown
                v-if="typesOptions && typesOptions.length > 0"
                class="me-1 mb-1"
                :color="selectedTypes && selectedTypes.length > 0 ? 'success' : 'secondary'"
                title="Filter by types"
                small
            >
                <li v-for="type in typesOptions" :key="type.id">
                    <button
                        @click.prevent="filterByTypes(type.id)"
                        class="dropdown-item text-xs"
                        :class="selectedTypes.includes(type.id) ? 'bg-primary text-white' : 'bg-white'"
                    >
                        {{ type.label }}
                    </button>
                </li>
            </Dropdown>
            <!-- Filter by store -->
            <Dropdown
                v-if="getStores && getStores.length > 0"
                class="me-1 mb-1"
                :color="selectedStore ? 'success' : 'secondary'"
                title="Filter by store"
                small
            >
                <li v-for="(store, index) in getStores" :key="index">
                    <button
                        @click.prevent="filterByStore(store.id)"
                        class="dropdown-item text-xs"
                        :class="selectedStore == store.id ? 'bg-primary text-white' : 'bg-white'"
                    >
                        {{ store.name }}
                    </button>
                </li>
            </Dropdown>
            <!-- Filter by agent -->
            <Dropdown
                v-if="agentStatuses.length > 0"
                class="mb-1"
                :color="selectedAgent ? 'success' : 'secondary'"
                title="Filter by agent"
                small
            >
                <li v-for="(agent, index) in agentStatuses" :key="index">
                    <button
                        @click.prevent="filterByAgent(agent)"
                        class="dropdown-item text-xs"
                        :class="selectedAgent == agent.type ? 'bg-primary text-white' : 'bg-white'"
                    >
                        {{ agent.name }}
                    </button>
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Last update</th>
                <th v-if="!isGroupTab">Store</th>
                <th>Agent</th>
                <th>User</th>
                <th>Group</th>
                <th>Partner</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageExpenseRequests')">
            <Button small @click="openModal('createExpenseRequest')" icon="plus">Create request</Button>
            <Modal
                v-if="isModalOpen('createExpenseRequest')"
                @close="closeModal('createExpenseRequest')"
                title="Create expense request"
            >
                <Update :values="params" @submitted="expenseRequestSubmitted" />
            </Modal>
        </template>
        <template v-slot:default>
            <tr v-for="(request, index) in expenseRequests" @click="open(request)" :key="index">
                <td>
                    <code>{{ request.id }}</code>
                </td>
                <td>{{ request.updated_at | formatDate }}</td>
                <td v-if="!isGroupTab">
                    <div v-if="request.user"><Store :id="request.user.store_id" /></div>
                </td>
                <td>
                    <AgentStatus v-if="request && request.agent" :agent="request.agent" minimal color="success" />
                </td>
                <td>
                    <div v-if="request.user">{{ request.user.name }}</div>
                </td>
                <td>
                    {{ request && request.group && request.group.name ? request.group.name : 'N/A' }}
                </td>
                <td>
                    <Badge v-if="request.partner" color="success" icon="handshake">{{
                        request.partner.name[$store.state.lang.displayLanguage]
                    }}</Badge>
                    <Badge v-else color="secondary" icon="question-circle">Partner unknown</Badge>
                </td>
                <td>{{ request.amount | currency }}</td>
                <td><ExpenseRequestStatus :status="request.status"></ExpenseRequestStatus></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Alerts, Badge, Button, Boolean, Datatable, Dropdown, Modal } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import { formatError } from '@/plugins/errors.js'
    import Types from '@/mixins/Types'
    import ExpenseRequestStatus from '@/components/ExpenseRequests/status'
    import Update from '@/components/ExpenseRequests/Update'
    import Store from '@/components/layout/Store'
    import statuses from '@/components/ExpenseRequests/statuses.js'
    import agentStatuses from '@/components/Agents/statuses.js'
    import AgentStatus from '@/components/Agents/Status'

    export default {
        name: 'ExpenseRequests',
        components: {
            AgentStatus,
            Badge,
            Button,
            Datatable,
            Dropdown,
            ExpenseRequestStatus,
            Modal,
            Store,
            Update,
        },
        mixins: [Modals, Types],
        props: {
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                expenseRequests: [],
                loading: true,
                pagination: { current_page: 1 },
                search: null,
                status: null,
                statuses,
                selectedStore: null,
                agentStatuses,
                selectedTypes: [],
                selectedAgent: null,
            }
        },
        computed: {
            getStores() {
                return this.$store.getters.getStores
            },
            isGroupTab() {
                return Object.keys(this.params).includes('groupId')
            },
        },
        methods: {
            async fetchData(search) {
                this.search = search
                this.loading = true
                let params = this.mergeParams({
                    isCancellable: true,
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    store_id: this.selectedStore ? this.selectedStore : undefined,
                    agent: this.selectedAgent,
                    includes: ['partner', 'user', 'group', 'agent'],
                    wallet_types: this.selectedTypes,
                })
                try {
                    const response = await this.getRequest(params)
                    this.expenseRequests = response.data
                    this.pagination = response.meta.pagination
                    this.loading = false
                } catch (error) {
                    if (error.code !== 'ERR_CANCELED') {
                        const errorTxt = formatError(error)
                        Alerts.notificationError(errorTxt)
                        this.loading = false
                    }
                }
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(expenseRequest) {
                let panel = this.openPanel('ExpenseRequestPanel', expenseRequest.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByStore(id) {
                if (this.selectedStore === id) {
                    this.selectedStore = null
                } else if (this.selectedStore !== id) {
                    this.selectedStore = id
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByTypes(id) {
                if (this.selectedTypes.includes(id)) {
                    this.selectedTypes = this.selectedTypes.filter((typeId) => typeId !== id)
                } else {
                    this.selectedTypes.push(id)
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByAgent(typeAgent) {
                if (this.selectedAgent === typeAgent.type) {
                    this.selectedAgent = null
                } else {
                    this.selectedAgent = typeAgent.type
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            expenseRequestSubmitted(expense) {
                this.fetchData(this.search)
                this.openPanel('ExpenseRequestPanel', expense.id)
                this.closeModal('createExpenseRequest')
            },
            getRequest(params) {
                if (this.isGroupTab) {
                    const { groupId, ...otherParams } = params
                    return this.$api.groups.getExpenseRequests(groupId, otherParams)
                }
                return this.$api.expenseRequests.get(params)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
