export default {
    data() {
        return {
            types: [],
        };
    },
    computed: {
        typesOptions() {
            if (!this.types) return [];
            const options = this.flatAndFormatTypes(this.types);
            return options;
        },
    },
    methods: {
        fetchTypes() {
            this.$api.types.get({ only_platform_visible: 1, depth: 1 }).then((types) => {
                this.types = types.data;
            });
        },
        flatAndFormatTypes(types) {
            let options = [];
            if (!types) return options;

            types.forEach((type) => {
                options.push({
                    id: type.id,
                    technical_name: type.technical_name,
                    label: type.name.en,
                });
                if (type.children && type.children.length > 0) {
                    options = options.concat(this.flatAndFormatTypes(type.children));
                }
            });

            return options;
        },
    },
    mounted() {
        this.fetchTypes();
    },
};
