<template>
    <FormRoot>
        <Field
            :form.sync="form"
            type="component"
            component="Users"
            name="supervisor_id"
            :searchFunction="fetchUsers"
            hint="Search for an existing user"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit">
                {{ hasSupervisor ? 'Update' : 'Add' }}
            </Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        props: {
            groupId: {
                type: Number,
                required: true,
            },
            hasSupervisor: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    supervisor_id: '',
                },
                messageSuccess: `The supervisor has been ${this.hasSupervisor ? 'updated' : 'added'}`,
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            submit() {
                return this.$api.groups.update(this.groupId, this.$data.form.data())
            },
            fetchUsers(search) {
                return this.$api.users.get({
                    search: search,
                })
            },
        },
    }
</script>
